<template>
  <div
    v-on-click-outside="()=>emits('closeDropdown')"
    class="z-4 absolute top-full right-0 px-3 py-2 rounded-4 shadow-[0_6px_18px_0] shadow-black/16 ring-1 ring-inset ring-#f1f1f1 bg-white w-100 flex flex-col gap-px max-w-[calc(100vw-var(--width-content-margin)*2)]"
  >
    <NuxtLink
      to="/profile"
      class="uno-1ycu2s group/lkdropdown"
    >
      <span class="mr-2 i-custom:document?bg"></span>
      <p>Мои заказы</p>
    </NuxtLink>
    <NuxtLink
      to="/profile/reviews"
      class="uno-1ycu2s group/lkdropdown"
    >
      <span class="mr-2 i-custom:star-outline-2?bg"></span>
      <p>Мои отзывы</p>
      <!--      <span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors">15</span>-->
    </NuxtLink>
    <NuxtLink
      to="/profile/info"
      class="uno-1ycu2s group/lkdropdown"
    >
      <span class="mr-2 i-custom:list-box-sharp?bg"></span>
      <p>Мои данные</p>
      <!-- <span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors"></span> -->
    </NuxtLink>
    <NuxtLink
      to="/profile/addreses"
      class="uno-1ycu2s group/lkdropdown"
    >
      <span class="mr-2 i-custom:pin?bg"></span>
      <p>Мои адреса</p>
      <span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors">{{ currentUser?.addresses?.length || 0 }}</span>
    </NuxtLink>
    <!-- <NuxtLink to="/profile"
				class="uno-1ycu2s group/lkdropdown">
				<span class="mr-2 i-custom:heart-outline-2?bg"></span>
				<p>Избранное</p>
				<span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors">8</span>
			</NuxtLink> -->
    <!--    <NuxtLink-->
    <!--      to="/"-->
    <!--      class="uno-1ycu2s group/lkdropdown"-->
    <!--    >-->
    <!--      <span class="mr-2 i-custom:add-layer?bg"></span>-->
    <!--      <p>Бонусы</p>-->
    <!--      <span class="text-seryy group-hover/lkdropdown:text-firmennyy-bright transition-colors">2560</span>-->
    <!--    </NuxtLink>-->
    <button
      class="uno-1ycu2s group/lkdropdown"
      @click="logOutHandler"
    >
      <span class="mr-2 i-custom:x-circle?mask text-seryy/50 group-hover/lkdropdown:text-firmennyy transition-colors"></span>
      <p>Выйти</p>
    </button>
  </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import { useUserAuth } from '~/composables/user/useUserAuth';
import { navigateTo, useRoute, cartStore as store, wishStore as wStore, userStore as uStore } from '#imports';

const route = useRoute();
const { logOut, currentUser } = useUserAuth();
const cartStore = store();
const wishStore = wStore();
const userStore = uStore();

const emits = defineEmits<{
	closeDropdown:[];
}>();

const logOutHandler = async () => {
  const res = await logOut();
  cartStore.setCartStore(null);
  wishStore.changeWishList(null);
  userStore.setCurrentAddress(null);

  if (res && res?.message === 'Success') {
    navigateTo('/');
  }
};

watch(() => route.path, () => {
  emits('closeDropdown');
});
</script>
